import React, { useState } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { Modal } from "react-bootstrap"

const AirportMarketingPage = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const data = useStaticQuery(graphql`
    query {
      videoImg: file(relativePath: { eq: "video-marketing.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      digitalImg: file(relativePath: { eq: "digital-marketing.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reportImg: file(relativePath: { eq: "marketing-report.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Airport Marketing" />
      <Hero
        pageTitle="Airport Marketing"
        breadcrumbText="Services"
        breadcrumbLink="/#services"
      />
      <section className="section-1 featured">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <span className="pre-title m-0">Tell Your Unique Story</span>
              <h2>
                Airport{" "}
                <span className="featured">
                  <span>Marketing</span>
                </span>
              </h2>
              <p className="text-max-800">
                The vast majority of people have little or no understanding of
                what actually takes place on general aviation airports. Our
                mission is to ensure that the key points are readily accessible,
                visually appealing, and easily understood by audiences with
                little or no aviation experience.
              </p>

              <blockquote>
                We produce insightful technical reports and studies that meet
                and exceed the stringent demands of seasoned aviation
                professionals.
              </blockquote>

              <p>
                In coordination with effective public outreach and involvement,{" "}
                Airport Marketing can be a valuable information tool in building
                awareness, education, developing consensus, and promoting better
                understanding and buy-in from the general public and elected
                officials. This is the foundation of an essential toolset for
                airports who understand the need to take control of their
                message and image.
              </p>
              <p>
                <em>
                  We want to help our clients be relevant to the stakeholders
                  and communities they serve.
                </em>
              </p>
            </div>
            <div className="col-lg-4 mt-5">
              <div className="d-none d-lg-block report-shaddow">
                <Img fluid={data.reportImg.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="video-production" className="section-2 odd">
        <div className="container ">
          <div className="row  intro">
            <div className="col-lg-6 ">
              <span className="pre-title m-0">Marketing Tools</span>
              <h2>
                Video{" "}
                <span className="featured">
                  <span>Production</span>
                </span>
              </h2>
              <p className="text-max-800">
                We have developed systemwide videos explaining the significant
                value of airports in the states of Delaware, Texas, New York,
                Pennsylvania, and for the 16 county North Central Texas region
                surrounding Dallas/Fort Worth. We have produced dozens of
                individual airport videos describing and illustrating the
                benefits of general aviation.
              </p>
              <Link to="/video-production">Explore more videos</Link>
            </div>
            <div className="col-lg-6 mt-5">
              <a
                onClick={handleShow}
                href="#!"
                data-toggle="modal"
                data-target="#videoModal"
                className="square-image d-flex justify-content-center align-items-center"
              >
                <i className="icon bigger fas fa-play clone"></i>
                <i className="icon bigger fas fa-play"></i>
                <Img
                  className="fit-image"
                  fluid={data.videoImg.childImageSharp.fluid}
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="digital-marketing" className="section-1 featured">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6">
              <span className="pre-title m-0">Marketing Tools</span>
              <h2>
                Digital{" "}
                <span className="featured">
                  <span>Marketing</span>
                </span>
              </h2>
              <p className="text-max-800">
                The first thing people see when searching for an airport online
                should be the airport’s Website, Facebook page, YouTube videos,
                Twitter profile, and any other Social Media outlet the airport
                uses to tell its story. These resources not only provide quick
                access to useful information, but also demonstrate the airport’s
                unique character and personality that sets it apart from other
                airports.
              </p>
              <p>
                Our goal is to provide airports with the tools they need to
                successfully implement their digital marketing strategy to the
                greatest audience possible. When an effective online presence is
                established, an airport can engage people in a way that
                cultivates relationships and builds a community of support. At
                the end of the day, these airports are poised to exceed customer
                service expectations, be more attentive to the needs of local
                businesses, and capitalize on opportunities that would otherwise
                be unavailable.
              </p>
            </div>

            <div className="col-lg-6 image-center">
              <Img
                className="mt-5"
                fluid={data.digitalImg.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-80w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            title="videoModal"
            className="embed-responsive-item"
            src={`https://www.youtube.com/embed/GnXHvH99IHE?rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </Layout>
  )
}

export default AirportMarketingPage
